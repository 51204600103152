<template>
  <div class="modal-card" style="width: 50vw">
    <header class="modal-card-head">
      <p class="modal-card-title">
        {{ $t('omw-marketing-clicks-appointment') }}: <b>{{ campaignName }}</b>
      </p>
    </header>

    <section class="modal-card-body">
      <b-table
        detailed
        detail-key="appointmentNumber"
        :sticky-header="true"
        :data="clickRollUp"
      >
        <b-table-column
          v-slot="props"
          field="apptNumber"
          :label="$t('omw-marketing-appointment-number')"
        >
          {{ props.row.appointmentNumber }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          field="totalClicks"
          :label="$t('omw-marketing-total-campaign-clicks')"
        >
          {{ props.row.totalClicks }}
        </b-table-column>
        <template slot="detail" slot-scope="props">
          <appointment-clicks
            :appointment-click-detail="
              campaignClickDetail[campaignName][props.row.appointmentNumber]
                .details
            "
          ></appointment-clicks>
        </template>
      </b-table>
    </section>
    <b-button icon-left="download" type="is-success" @click="exportData">{{
      $t('omw-marketing-export-json')
    }}</b-button>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import { mapGetters } from 'vuex';
import flatifyObject from 'flatify-obj';
import { exportJson } from '@/lib/exportData.js';
import AppointmentClicks from './detail/AppointmentClicks.vue';

export default defineComponent({
  name: 'CampaignClicks',
  components: {
    AppointmentClicks,
  },
  props: {
    campaign: {
      type: String,
      required: true,
      default: '',
    },
  },
  computed: {
    ...mapGetters('storeMarketing', ['campaignClickDetail']),
    clickRollUp() {
      return this.appointmentNumbers.map((appt) => {
        return {
          appointmentNumber:
            this.campaignClickDetail[this.campaign.campaignName][appt]
              .apptNumber,
          totalClicks:
            this.campaignClickDetail[this.campaign.campaignName][appt]
              .totalClicks,
        };
      });
    },
    appointmentNumbers() {
      if (!this.selectedCampaignDetail) return [];
      const apptNumbers = Object.keys(this.selectedCampaignDetail);
      return apptNumbers;
    },
    selectedCampaignDetail() {
      return this.campaignClickDetail[this.campaign.campaignName];
    },
    csvFriendlyClickData() {
      const campaignKeys = Object.keys(this.campaignClickDetail);
      return campaignKeys.map((campaignKey) => {
        return {
          ...flatifyObject(this.campaignClickDetail[campaignKey]),
        };
      });
    },
    csvHeadings() {
      const headings = new Set();
      this.csvFriendlyClickData.forEach((entry) => {
        const keys = Object.keys(entry);
        keys.forEach((key) => {
          headings.add(key);
        });
      });
      return Array.from(headings);
    },
  },
  methods: {
    exportData() {
      exportJson(
        'appointmentClicksCampaign',
        null,
        this.campaignClickDetail[this.campaignName],
        true,
      );
    },
  },
});
</script>

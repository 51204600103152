<template>
  <validation-observer v-slot="{ invalid }">
    <div>
      <div class="modal-card" style="width: 35vw">
        <header class="modal-card-head">
          <p class="modal-card-title">
            {{ $t('omw-marketing-update-title') }}: {{ campaignName }}
          </p>
        </header>

        <section class="modal-card-body">
          <b-field label="Name">
            <b-input
              v-model="campaignName"
              type="text"
              :placeholder="$t('omw-marketing-image-name')"
              :disabled="true"
            >
            </b-input>
          </b-field>
          <b-input
            :label="$t('omw-marketing-dataset')"
            :value="campaign.datasetId"
            disabled
          />
          <validation-provider
            v-slot="{ errors }"
            rules="httpRegex"
            name="Click-through URL"
          >
            <b-field label="Click-through URL">
              <b-input
                v-model="clickUrl"
                type="text"
                :placeholder="$t('omw-marketing-clickthrough')"
                name="Click-through URL"
              >
              </b-input>
            </b-field>
            <span class="help is-danger">
              {{ errors[0] }}
            </span>
          </validation-provider>
          <b-field label="Add some categories">
            <b-taginput
              v-model="categories"
              ellipsis
              icon="tag"
              type="is-dark"
              :placeholder="$t('omw-marketing-add-category')"
            >
            </b-taginput>
          </b-field>
          <b-field :label="$t('omw-marketing-make-campaign-default')">
            <b-switch
              v-model="isDefault"
              :true-value="true"
              :false-value="false"
            >
              {{ isDefault ? $t('general-yes') : $t('general-no') }}
            </b-switch>
          </b-field>
        </section>
        <footer class="modal-card-foot">
          <b-field>
            <b-button
              type="is-info"
              :disabled="invalid"
              @click="updateCampaignMetadata()"
            >
              Update
            </b-button>
            <b-button @click="$parent.close()">
              {{ $t('omw-marketing-close') }}
            </b-button>
          </b-field>
        </footer>
      </div>
    </div>
  </validation-observer>
</template>

<script>
import { updateCampaign } from '@/services/marketing/index.js';

import {
  ValidationProvider,
  ValidationObserver,
  setInteractionMode,
  extend,
} from 'vee-validate';

import { defineComponent } from '@vue/composition-api';

setInteractionMode('aggressive');

export default defineComponent({
  name: 'CampaignUpdate',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    campaign: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      file: undefined,
      httpRegex:
        // eslint-disable-next-line max-len
        /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
      clickUrl: undefined,
      campaignName: undefined,
      categories: [],
      isDefault: false,
    };
  },
  beforeCreate() {
    extend('httpRegex', {
      message: (field) => {
        let msg = field;
        msg += ` ${this.$t('omw-marketing-url-validation-msg')}`;
        return msg;
      },
      validate: (value) => {
        const httpRegex = RegExp(this.httpRegex);
        return httpRegex.test(value);
      },
    });
  },
  created() {
    this.categories = this.campaign.categories;
    this.campaignName = this.campaign.campaignName;
    this.clickUrl = this.campaign.clickUrl;
    this.isDefault = this.campaign.isDefault;
  },
  methods: {
    async updateCampaignMetadata() {
      try {
        this.$emit('loading', true);
        await updateCampaign({
          campaignName: this.campaignName,
          clickUrl: this.clickUrl,
          categories: this.categories,
          isDefault: this.isDefault,
        });
        this.$buefy.toast.open({
          duration: 5000,
          message: `Campaign metadata updated successfully`,
          position: 'is-top',
          type: 'is-info',
        });
      } catch (err) {
        this.error = err;
        this.$buefy.toast.open({
          duration: 10000,
          message: `Error updating campaign metadata ${err}`,
          position: 'is-top',
          type: 'is-danger',
        });
      } finally {
        this.$emit('update-complete');
        this.$emit('loading', false);
      }
    },
  },
});
</script>
